
import { Component, Vue } from 'vue-property-decorator';
import { reauthenticate } from '@/shared/auth';
import { get } from '@/shared/requests';

@Component({})
export default class Instructor extends Vue {
  loading = true;

  error = '';

  showInprogressJournals = false;

  journals: {
    name: string;
    courses: string[];
    id: string;
    dueAt: string;
  }[] = [];

  timeRegex = /:\d{2}(?=[^:])/;

  mounted() {
    this.getVisibleJournals();
  }

  async getVisibleJournals() {
    const { success, data, error, status } = await get('/instructor/journals');

    this.$set(this, 'loading', false);

    if (success && data) {
      this.$set(this, 'journals', data.journals);
    } else if (error) {
      console.error(error);
      if (status === 401 && error.message === 'Unauthorized') reauthenticate('/instructor');
      this.$set(this, 'error', 'Unable to get available journals.');
    }
  }
}
